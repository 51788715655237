import React from 'react'
import Event from './Event'
import './Event.css'
const Events = () => {
    return (
        <div>
            <h1>Technical Events</h1>
            <div className='d-flex overflow-scroll'>
                <Event />
                <Event />
                <Event />
            </div>
            <h1>Cultural Events</h1>
            <div className='d-flex overflow-scroll'>
                <Event color='yellow' />
                <Event color='yellow' />
                <Event color='yellow' />
            </div>
        </div>
    )
}

export default Events