import React from 'react'
import './EditEvent.css'
const EditEvent = () => {
    return (

        <div className='form-group'>
            <h1>Add Event</h1>
            <h3 className='mt-5'>Event Categories</h3>
            <div className='form-row'>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label class="form-check-label" for="flexRadioDefault1">
                        Technical
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                    <label class="form-check-label" for="flexRadioDefault2">
                        Cultural
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                    <label class="form-check-label" for="flexRadioDefault2">
                        Management
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                    <label class="form-check-label" for="flexRadioDefault2">
                        Miscellaneous
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                    <label class="form-check-label" for="flexRadioDefault2">
                        Technical
                    </label>
                </div>
            </div>
            <div className='form-group'>
                <h1>Event Name</h1>
                <input type="text" className='form-control mt-3 mb-5' placeholder='Enter Name' />
                <h1>Society Name</h1>
                <input type="text" className='form-control mt-3 mb-5' placeholder='Add Image' />

                <h1>Description</h1>
                <textarea name="" id="" cols="30" rows="5" className='form-control mb-5' placeholder='Description'></textarea>
                <h1>Start Date</h1>
                <input type="date" className='form-control mt-3 mb-5' placeholder='Add Image' />
            </div>
            <div className='d-flex justify-content-start'>
                <button className='btn btn-primary mt-3 '>Add</button>
            </div>
        </div>
    )
}

export default EditEvent