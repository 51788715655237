import React from 'react'
import './navbar.css'
import Gates from './Images/Gates-Logo.png';
const Navbar = () => {
    return (
        <div className='navbar'>
            <div>
                <img src={Gates} alt="" />
                <h1>Admin Portal</h1>
            </div>
            <p>
                User Id
            </p>
        </div>
    )
}

export default Navbar