import React from 'react'
import './Event.css'
import triangle from './Images/Polygon 6.png'
import Calendar from './Images/Calendar.png'
import edit from './Images/Edit.png'
import del from './Images/Delete.png'
const Event = (params) => {
    console.log(params.color);
    return (
        <div className='m-3'>
            <div className={'event ' + params.color}>
                <h3>Event Name</h3>
                <span><img src={triangle} /> GDSC Society</span>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Reprehenderit amet repellat saepe reiciendis explicabo! Temporibus doloribus harum quasi suscipit quibusdam!</p>
                <span><img src={Calendar} />12/05/22</span>
            </div>
            <div className='edit-delete'><a href="/event/edit"><img src={edit} alt="" /></a>
                <a href="/event/edit"><img src={del} alt="" /></a></div>
        </div>
    )
}

export default Event