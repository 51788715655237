import { React, useState } from 'react'
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import Card from './Card';
import Date from './Date';
import EditEvent from './EditEvent';
import Events from './Events';
import './mainbar.css'
import Trailer from './Trailer';
const MainBar = () => {
    const [active, setActive] = useState("FirstCard");
    return (
        <div className='main-body'>
            <Router>
                <Switch>
                    <Route path="/trailer" exact>
                        <Trailer />
                    </Route>
                    <Route path="/dates" exact>
                        <Date />
                    </Route>
                    <Route path="/events" exact>
                        <Events />
                    </Route>
                    <Route path="/event/edit" exact>
                        <EditEvent />
                    </Route>
                    <Redirect to="/" />
                </Switch>
            </Router>

        </div>
    )
}

export default MainBar