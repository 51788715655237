import { React, useState } from 'react'

const Trailer = () => {
    const [videoLink, setVideoLink] = useState("");
    const changeHandler = (e) => {
        let link = e.target.value;
        let position = link.search("=");
        setVideoLink("https://www.youtube.com/embed/" + link.substring(position + 1, link.length));
    }
    return (
        <div className='form-group'>
            <h1>Trailer</h1>
            <textarea name="" id="" cols="30" rows="5" className='form-control' placeholder='Description'></textarea>
            <input type="url" className='form-control mt-3' placeholder='URL Link' name='URL' onChange={changeHandler} />
            <iframe width="100%" height="300" className='mt-3'
                src={videoLink}>
            </iframe>
            <div className='d-flex justify-content-end'>
                <button className='btn btn-primary mt-3 '>Update</button>
                <button className='btn btn-danger mt-3 ml-3'>Delete</button>
            </div>
        </div>
    )
}

export default Trailer