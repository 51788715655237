import React from 'react'

const Date = () => {
    return (
        <div>
            <h1>Dates</h1>
            <div className='form-row'>

                <div className="col">
                    <label htmlFor="" className='form-control'>Start Date</label>
                    <input type="date" className='form-control' />
                </div>
                <div className="col">
                    <label htmlFor="" className='form-control'>End Date</label>
                    <input type="date" className='form-control' />
                </div>

            </div>
            <button className='btn btn-primary mt-3'>Update</button>
        </div>
    )
}

export default Date